.small-page-container {
  width: 100%;
  padding: 96px 16px 88px;
  margin: 0 auto;

  h2 {
    line-height: 1.32;
    padding: 40px 0 8px;
  }

  @media only screen and (min-width: 701px) {
    width: 688px;
    padding: 96px 0 88px;
  }
}

.collapsible-block {
  width: 100%;
  background: var(--color-gray-light-97);
  border-radius: 6px;
  padding: 0 16px;
  margin-bottom: 8px;

  dt {
    position: relative;
    min-height: 64px;
    font-family: "Poppins-Medium";
    font-size: 18px;
    color: var(--color-dark-10);
    padding: 18px 16px;
    padding-left: 40px;
    cursor: pointer;

    &::before {
      @include transition(transform 0.2s ease-in-out);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/chevron-right.svg")
        no-repeat center;
      width: 16px;
      height: 100%;
    }

    &.open {
      &::before {
        transform: rotate(90deg);
      }
    }
  }

  dd {
    @include transition(all 0.3s ease-in-out);
    max-height: 0;
    overflow: hidden;
    border-color: var(--color-gray-light-90);

    > span,
    p,
    li {
      font-size: 18px;
      color: var(--color-dark-15);
      line-height: 1.75;

      .p-16-0 {
        padding: 16px 0;
      }

      .p-b-0 {
        padding-bottom: 0;
      }

      .p-t-0 {
        padding-top: 0;
      }
    }

    > span > p {
      padding: 16px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &.open {
      max-height: 3500px;
      height: auto;
      padding: 16px 0;
      border-top: 1px solid var(--color-gray-light-90);
    }

    ul {
      list-style-type: none;
    }

    li {
      padding: 16px 0;

      &::before {
        display: inline;
        content: "\25CB";
        padding: 0 8px 0 4px;
      }

      > p {
        padding: 16px 0;
      }

      ul {
        padding: 16px 0 0 16px;
        li {
          padding: 0;
        }
      }
    }

    a {
      color: var(--color-primary);
      text-decoration: underline;
    }
  }
}

.update-info {
  font-family: "Poppins-Semibold";
  font-size: 16px;
  color: var(--color-gray);
  text-transform: uppercase;
  padding: 8px 0;
}
