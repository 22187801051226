@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Italic";
  src: url("../fonts/Poppins-Italic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  color: var(--color-dark-10);
  background: var(--color-white);
  padding: 0;
  margin: 0;
}

* {
  color: var(--color-dark-10);
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Poppins-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

h1 {
  font-family: "Poppins-Bold";
  font-size: 48px;
  line-height: 1.2;
}

h2,
h3 {
  font-family: "Poppins-SemiBold";
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-family: "Poppins-Medium";
  font-size: 18px;
  color: var(--color-dark-15);
}

a,
button {
  font-size: 18px;
}

p {
  font-size: 18px;
  color: var(--color-dark);
  line-height: 1.75;

  &.large-text {
    font-family: "Poppins-SemiBold";
    font-size: 36px;
    color: var(--color-dark-10);
    line-height: 1.32;

    &.about-text {
      padding: 0;
      padding-bottom: 24px;

      @media only screen and (min-width: 769px) {
        padding: 16px 32px 16px 16px;
      }
    }
  }

  i {
    color: inherit;
  }
}

a {
  color: var(--color-dark-10);
  text-decoration: none;
}

b {
  font-family: "Poppins-Medium";
  color: inherit;
  font-weight: normal;
}

#root {
  width: 100%;
  margin: 0 auto;
}
