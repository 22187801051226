input,
textarea {
  width: 100%;
  font-size: 18px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-light);
  border-radius: 6px;
  padding: 8px 16px;

  &::-webkit-input-placeholder {
    color: var(--color-gray);
  }

  &:-ms-input-placeholder {
    color: var(--color-gray);
  }

  &::-ms-input-placeholder {
    color: var(--color-gray);
  }

  &::placeholder {
    color: var(--color-gray);
  }

  &:focus,
  &:active {
    border: 1px solid var(--color-primary);
    outline: none;
  }

  &:active {
    box-shadow: 0px 0px 0px 3px rgba(64, 144, 200, 0.3);
  }

  &.invalid {
    border-color: var(--color-red-50);
    background: var(--color-red-95);
  }
}

input {
  height: 64px;
}

textarea {
  min-height: 160px;
}

form {
  max-width: 424px;
  padding: 80px 0;

  > h2 {
    padding-top: 56px;
  }

  @media only screen and (max-width: 834px) {
    padding-top: 16px;
    margin: 0 auto;

    > h2 {
      padding-top: 0;
    }
  }
}

.input-group {
  position: relative;
  padding: 8px 0 24px;

  > label {
    font-family: "Poppins-Medium";
    font-size: 16px;
    color: var(--color-dark);
    line-height: 1.5;

    &.invalid {
      color: var(--color-red-50);
    }
  }
}
