section {
  width: 100%;

  &.with-margin-bottom {
    margin-bottom: 40px;
  }

  &.first-item {
    height: calc(100vh - 80px);
  }

  @media only screen and (max-width: 650px) {
    padding-bottom: 80px;

    &.first-item {
      height: auto;
    }
  }
}

.wide-width {
  width: 57.5%;

  @media only screen and (max-width: 1024px) {
    width: 55%;
  }

  @media only screen and (max-width: 650px) {
    width: 100%;
  }
}

.medium-width {
  width: 42.5%;

  @media only screen and (max-width: 1024px) {
    width: 45%;
  }

  @media only screen and (max-width: 650px) {
    width: 100%;
  }
}

.small-width {
  width: 33.333%;

  @media only screen and (max-width: 650px) {
    width: 100%;
  }
}

.width-50 {
  width: 50%;

  @media only screen and (max-width: 834px) {
    width: 100%;

    &.mobile-transform {
      width: 50%;
    }
  }

  @media only screen and (max-width: 768px) {
    &.mobile-transform {
      width: 100%;
    }
  }
}

.text-wrapper {
  @extend %display-flex;
  padding-bottom: 24px;

  &.with-padding-bottom {
    padding-bottom: 80px;
  }

  > div {
    padding: 16px;

    &:first-child {
      padding-right: 32px;
    }

    &:last-child {
      padding-left: 32px;
    }
  }

  @media only screen and (max-width: 1024px) {
    > div {
      padding: 8px;

      &:first-child {
        padding-right: 24px;
      }

      &:last-child {
        padding-left: 16px;
        padding-right: 0;
      }
    }
  }

  @media only screen and (max-width: 650px) {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &.with-padding-bottom {
      padding-bottom: 32px;
    }

    > div {
      width: 100%;
      padding: 0 0 48px 0;

      &:last-child {
        padding-left: 0;
      }
    }
  }
}

.text-block {
  @extend %display-flex;
  @extend %flex-direction-column;
  @extend %justify-content-center;
  width: 57.5%;

  > * {
    margin: 12px 0;
  }

  > img {
    width: 140px;
  }

  &.padding-left {
    padding-left: 88px;
  }

  @media only screen and (max-width: 1024px) {
    width: 55%;

    &.padding-left {
      padding-left: 64px;
    }
  }

  @media only screen and (max-width: 650px) {
    width: 100%;

    &.padding-left {
      padding-left: 0;
    }
  }
}

.media-column {
  width: 42.5%;

  @media only screen and (max-width: 1024px) {
    width: 45%;
  }

  @media only screen and (max-width: 650px) {
    width: 100%;
  }
}

.primary-link-wrapper {
  display: block;
  padding-top: 16px;

  > a {
    @extend %transition;
    color: var(--color-primary);
    line-height: 24px;

    &::after {
      @extend %transition;
      display: inline-block;
      content: "";
      width: 16px;
      height: 24px;
      font-size: 24px;
      line-height: 24px;
      vertical-align: middle;
      background: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/chevron-right.svg")
        no-repeat center;
      margin-left: 12px;
    }

    &:hover,
    &:active {
      color: var(--color-primary-hover);

      &::after {
        margin-left: 16px;
      }
    }
  }
}

.image-block {
  width: 100%;
  overflow: hidden;

  img {
    @extend %object-fit-cover;
    width: 100%;
  }

  @media only screen and (max-width: 650px) {
    margin-bottom: 48px;
  }
}

.heading-image {
  height: 92px;
  padding: 16px 0;

  > img {
    @extend %object-fit-cover;
    height: 56px;
  }
}

.partners-wrapper {
  width: 100%;
  text-align: center;

  > h2 {
    padding-top: 88px;
  }

  > p {
    width: 660px;
    padding: 24px 0;
    margin: 0 auto;
  }

  @media only screen and (max-width: 1024px) {
    > p {
      width: 512px;
    }
  }

  @media only screen and (max-width: 650px) {
    > p {
      width: 340px;
    }
  }

  @media only screen and (max-width: 370px) {
    > p {
      width: 100%;
    }
  }
}

.partners-content {
  @extend %display-flex;
  @extend %flex-wrap;
  @extend %justify-content-center;
  width: 100%;
  padding: 24px 12.5%;

  > h4 {
    width: 100%;
    padding-bottom: 24px;
  }

  > .small-width {
    position: relative;
    padding-bottom: 15.2%;

    img {
      @include transform(translate3d(-50%, -50%, 0));
      @extend %object-fit-contain;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60%;
      height: 80%;
    }
  }

  @media only screen and (max-width: 650px) {
    > .small-width {
      padding-bottom: 45%;
    }
  }
}

.store-links-wrapper {
  padding-bottom: 96px;

  > a {
    @include transition(all 0.3s ease-in-out);
    display: inline-block;
    margin: 0 8px;

    &:hover {
      @include transform(scale(1.05));
    }
  }
}

.slides-wrapper {
  width: 100%;
  background: linear-gradient(36.8deg, #7474bf 0%, #348ac7 100%);

  > section {
    height: 100vh;

    > div {
      height: 100vh;
    }
  }

  p {
    max-width: 480px;
    color: var(--color-white);
  }

  @media only screen and (max-width: 650px) {
    > section {
      height: auto;

      > div {
        height: auto;
      }
    }
  }
}

.slide-image-wrapper {
  height: 100%;
  text-align: right;
  padding: 48px 16px 48px 0;

  > img {
    height: 100%;
  }

  @media only screen and (max-width: 1024px) {
    padding: 48px 0 48px 16px;
  }

  @media only screen and (max-width: 650px) {
    padding: 80px 0 32px 0;
  }
}

// Contact us

.contact-text-block {
  max-width: 456px;
  padding-right: 32px;

  h1 {
    padding-top: 32px;
  }

  p {
    padding-top: 32px;
  }

  > img {
    height: 100%;
    margin-top: 40px;
  }

  @media only screen and (max-width: 834px) {
    max-width: 424px;
    padding: 80px 0 0;
    margin: 0 auto;

    h1 {
      padding-top: 0;
    }

    p {
      padding-top: 16px;
    }

    > img {
      margin-top: 24px;
    }
  }
}

// Request a Demo

.request-image-block {
  max-width: 456px;
  padding-right: 80px;

  > img {
    height: 100%;
    margin-top: 24px;
  }

  @media only screen and (max-width: 834px) {
    padding-right: 0;
    margin: 0 auto;
  }
}

// About

.simple-flex {
  @extend %display-flex;
  @extend %flex-wrap;
  width: 100%;
  padding: 16px 0;
}

.responsive-image {
  @extend %object-fit-contain;
  width: 100%;
}

.about-small-text {
  padding: 16px 0;

  @media screen and (min-width: 769px) {
    padding: 16px;
  }
}

.dashed-before {
  &::before {
    content: "";
    display: block;
    width: 114px;
    height: 4px;
    background: var(--color-dark-10);
    border-radius: 8px;
    margin: 16px 0 24px;
  }
}

.highlighted-block {
  text-align: center;
  background: var(--color-gray-light-97);
  padding: 96px 16px;

  > p {
    max-width: 688px;
    font-family: "Poppins-Medium";
    margin: 0 auto;
  }
}

.about-first-container {
  width: 100%;
  min-height: calc(100vh - 80px);
  padding-top: 40px;

  @media only screen and (min-width: 835px) {
    min-height: auto;
  }

  @media only screen and (min-height: 1081px) {
    min-height: auto;
  }
}

.with-background {
  background-image: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/request-demo.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  padding-bottom: 80px;

  @media only screen and (min-width: 651px) {
    background-size: 80%;
  }

  @media only screen and (min-width: 835px) {
    background-position: center left;
    background-size: 50%;
  }

  @media only screen and (min-width: 1281px) {
    background-size: contain;
  }
}

.adjustment-form-container {
  @extend .adjustment-container;
  @extend %flex-wrap;
  @extend %align-items-center;
  min-height: 100%;

  @media only screen and (min-height: 1050px) {
    min-height: auto;
  }

  @media only screen and (min-width: 1281px) {
    @media only screen and (min-height: 1550px) {
      &.with-background {
        background-size: 50%;
      }
    }
  }
}

.about-text-block {
  @extend %display-flex;
  @extend %flex-direction-column;
  @extend %justify-content-center;
  width: 100%;

  > * {
    margin: 12px 0;
  }

  @media only screen and (min-width: 835px) {
    width: 55%;
  }

  @media only screen and (min-width: 1025px) {
    width: 57.5%;
    max-width: 568px;
  }
}

.gray-uppercase {
  font-family: "Poppins-SemiBold";
  font-size: 24px;
  color: var(--color-gray);
  text-transform: uppercase;
}

.about-row {
  @extend %display-flex;
  @extend %align-items-center;
  @extend %flex-wrap;
  width: 100%;
  padding: 96px 0;
}

.about-text-column {
  width: 100%;

  > p {
    padding: 24px 0 0;
  }

  @media only screen and (min-width: 769px) {
    width: calc(100% - 304px);

    > p {
      padding: 16px;
    }
  }

  @media only screen and (min-width: 901px) {
    width: calc(100% - 360px);
    padding-left: 16px;
  }

  @media only screen and (min-width: 1201px) {
    width: 57.5%;
  }
}

.about-image-column {
  @extend %background-cover;
  width: 100%;
  height: auto;
  padding-bottom: 130%;
  border-radius: 8px;
  overflow: hidden;
  background-image: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/about-2.png");

  @media only screen and (min-width: 769px) {
    width: 304px;
    height: 100%;
    padding-bottom: 0;
  }

  @media only screen and (min-width: 901px) {
    width: 360px;
  }

  @media only screen and (min-width: 1201px) {
    width: 42.5%;
  }
}

.bold-text {
  font-family: "Poppins-SemiBold";
  font-size: 24px;
  color: var(--color-dark-10);

  @media only screen and (min-width: 769px) {
    &.padding {
      padding: 0 16px;
    }
  }
}

.decoration-block {
  @extend %background-cover;
  width: 100%;
  padding-bottom: 140%;
  border-radius: 6px;
  overflow: hidden;
  background-image: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/about-3.png");

  @media only screen and (min-width: 769px) {
    width: 33.333%;
    height: 100%;
    padding-bottom: 0;
  }
}

.width-66 {
  @extend %display-flex;
  @extend %flex-wrap;
  width: 100%;

  > h2 {
    width: 100%;
  }

  > div {
    width: 100%;
    padding: 24px 0;
  }

  @media only screen and (min-width: 769px) {
    width: 66.666%;

    > div {
      width: 50%;
      padding: 32px 24px 16px 0;
    }
  }
}

.background-decoration {
  @extend %background-cover;
  background-image: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/about-1.png");
  border-radius: 6px;
  overflow: hidden;
  padding-bottom: 200%;

  @media only screen and (min-width: 769px) {
    padding-bottom: 0;
  }
}

.additional-page-wrapper {
  @extend %display-flex;
  @extend %flex-direction-column;
  @extend %justify-content-center;
  @extend %align-items-center;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 600px;

  > a,
  h1,
  p {
    text-align: center;
    margin: 16px 0;
  }

  > .button-primary-link {
    width: auto;
  }

  > .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    &.no-match {
      background-image: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/no-match-page.png");
      opacity: 0.5;
    }

    &.success {
      background-image: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/success-page.png");
      opacity: 0.2;
    }

    @media only screen and (min-width: 650px) {
      background-size: 50%;
    }

    @media only screen and (min-width: 1281px) {
      background-size: 38%;
    }
  }

  @media only screen and (min-width: 769px) {
    min-height: 700px;
  }
}

.error-message {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 16px;
  color: var(--color-red-50);
  line-height: 1.5;

  &::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("https://cheqmate-images.s3.amazonaws.com/qa-landing-images/error.svg")
      no-repeat center;
    vertical-align: middle;
    margin-right: 8px;
  }
}

.logo-small {
  width: 44px;
  height: 56px;
  margin: 0 auto -64px;
}
