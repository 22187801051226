:root {
  --color-white: #ffffff;
  --color-white-transparent: rgb(255 255 255 / 0.95);
  --color-dark: #062b47;
  --color-dark-10: #385266;
  --color-dark-15: #697d8c;
  --color-gray: #9ba8b1;
  --color-gray-light: #ced3d8;
  --color-gray-light-97: #f7f8f8;
  --color-gray-light-90: #e3e7e8;
  --color-primary: #0f74b9;
  --color-primary-hover: #4090c8;
  --color-red-50: #e61a2b;
  --color-red-95: #fce8ea;

  --gradient-primary: linear-gradient(90.53deg, #4090c8 0.11%, #0f74b9 99.54%);
}
