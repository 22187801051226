.button-primary-link {
  @include transition(all 0.3s linear);
  display: block;
  height: 64px;
  font-family: "Poppins-Medium";
  color: var(--color-white);
  line-height: 64px;
  text-align: center;
  background-color: var(--color-primary);
  background-image: var(--gradient-primary);
  padding: 0 40px;
  border-radius: 8px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include transition(all 0.3s linear);
      background-color: var(--color-primary-hover);
      background-image: var(--color-primary-hover);
    }
  }

  &.small-size {
    width: 230px;
  }

  @media only screen and (max-width: 650px) {
    &.small-size {
      &.mobile-large-size {
        width: 100%;
      }
    }
  }
}
