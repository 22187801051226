.overflow-hidden {
  overflow: hidden;
}

.p-16-0 {
  padding: 16px 0;
}

.p-b-0 {
  padding-bottom: 0;
}

.p-t-0 {
  padding-top: 0;
}

.m-t-16 {
  margin-top: 16px;
}
