@import "./mixins";
@import "./basic";
@import "./buttons";
@import "./links";
@import "./inputs";
@import "./layout";
@import "./pages";
@import "./terms";
@import "./helpers";
@import "./loader.scss";
@import "./variables";

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
