button {
  @include transition(all 0.3s linear);
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &.btn-primary {
    width: 100%;
    height: 64px;
    font-family: "Poppins-Medium";
    color: var(--color-white);
    padding: 0 40px;
    border-radius: 8px;
    background-color: var(--color-primary);
    background-image: var(--gradient-primary);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        @include transition(all 0.3s linear);
        background-color: var(--color-primary-hover);
        background-image: var(--color-primary-hover);
      }
    }
  }

  &.small-size {
    width: 230px;
  }

  @media only screen and (max-width: 650px) {
    &.small-size {
      &.mobile-large-size {
        width: 100%;
      }
    }
  }
}

.menu-button {
  display: block;
  position: relative;
  left: 8px;
  width: 40px;
  height: 40px;
  font-size: 0;
  text-indent: -9999px;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
  padding: 0;
  z-index: 3;

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 8px;
    right: 8px;
    height: 2px;
    background: var(--color-dark);
    border-radius: 4px;

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--color-dark);
      border-radius: 4px;
      content: "";

      -webkit-transition-duration: 0.3s, 0.3s;
      transition-duration: 0.3s, 0.3s;
      -webkit-transition-delay: 0.3s, 0;
      transition-delay: 0.3s, 0;
    }

    &::before {
      top: 6px;
      -webkit-transition-property: top, -webkit-transform;
      transition-property: top, transform;
    }

    &::after {
      bottom: 6px;
      -webkit-transition-property: bottom, -webkit-transform;
      transition-property: bottom, transform;
    }
  }

  &.active {
    span {
      background: none;

      &::before {
        top: 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &::after {
        bottom: 0;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &::before,
      &::after {
        background-color: var(--color-gray);
        -webkit-transition-delay: 0, 0.3s;
        transition-delay: 0, 0.3s;
      }
    }
  }

  @media only screen and (max-width: 650px) {
    left: 24px;
  }

  @media only screen and (min-width: 769px) {
    display: none;
  }
}
