%display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

%flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

%align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

%justify-content-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

%justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

%flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

%transition {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

%object-fit-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

%object-fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

%background-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transition($property) {
  -webkit-transition: $property;
  -o-transition: $property;
  transition: $property;
}
