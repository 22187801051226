header {
  width: 100%;
  height: 80px;
  background: var(--color-white-transparent);
  padding: 0;
  z-index: 1;
}

.header-navigation {
  @extend %display-flex;
  @extend %align-items-center;
  @extend %justify-content-end;
  position: relative;
  max-width: 1366px;
  width: 100%;
  height: 100%;
  padding: 0 12%;
  margin: 0 auto;

  a {
    margin-left: 16px;

    &.logo {
      position: absolute;
      left: 12%;
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 1367px) {
    padding: 0 164px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 0 32px;

    a.logo {
      left: 32px;
    }
  }

  @media only screen and (max-width: 650px) {
    a.logo {
      left: 16px;
    }
  }
}

.header-navigation,
.footer-navigation {
  a {
    position: relative;
    font-family: "Poppins-SemiBold";
    font-size: 16px;
    color: var(--color-dark-15);
    text-transform: uppercase;
    letter-spacing: 0.04em;

    &::after {
      @include transform(translateX(-50%));
      @extend %transition;
      position: absolute;
      bottom: 0;
      left: 50%;
      content: "";
      width: 0px;
      height: 2px;
      background: var(--color-dark-15);
      border-radius: 2px;
    }

    &.active::after {
      width: 24px;
    }

    &.logo {
      display: inline-block;
      width: 140px;
      height: 48px;

      &::after {
        display: none;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover::after {
        width: 24px;
      }
    }
  }
}

.hide-on-mobile {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.mobile-menu-wrapper {
  @include transform(translateX(100%));
  @extend %transition;
  @extend %display-flex;
  @extend %flex-direction-column;
  @extend %justify-content-center;
  @extend %align-items-center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-white);

  &.open {
    @include transform(translateX(0));
    z-index: 2;
  }

  @media only screen and (min-width: 769px) {
    display: none;
  }
}

.mobile-menu {
  text-align: center;
  a {
    display: block;
    margin: 0 0 24px;

    &.logo {
      position: static;
    }
  }

  > div {
    &::before {
      content: "";
      display: block;
      width: 74px;
      height: 2px;
      background-color: var(--color-gray-light-90);
      margin: 0 auto 24px;
    }
  }
}

footer {
  text-align: center;
  background: var(--color-gray-light-97);
  padding: 64px 0;
}

.footer-navigation {
  a {
    height: 24px;
    margin: 0 10px;

    &.logo {
      height: auto;
      margin-bottom: 16px;
    }
  }

  > div {
    padding: 8px 0;
  }
}

.copy-right {
  font-size: 16px;
  color: var(--color-gray);
  padding-top: 8px;
}

.main-container {
  @extend %display-flex;
  @extend %flex-wrap;
  width: 100%;
  min-height: calc(
    100vh - 387px
  ); // 307px - height of footer, 80px - height of header

  > section:first-child {
    min-height: calc(100vh - 80px); // 80px - height of header
  }

  @media only screen and (max-width: 650px) {
    > section:first-child {
      min-height: auto;
    }
  }
}

.adjustment-container {
  @extend %display-flex;
  max-width: 1366px;
  width: 100%;
  min-height: 100vh;
  padding: 0 12%;
  margin: 0 auto;

  &.content {
    @extend %flex-wrap;
    min-height: auto;
  }

  &.first-item {
    min-height: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 1367px) {
    padding: 0 164px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 0 32px;
  }

  @media only screen and (max-width: 650px) {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: auto;
    padding: 0 16px;
  }

  &.responsive-first-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: auto;
    height: auto;

    @media only screen and (min-width: 835px) {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      min-height: auto;
      height: auto;
    }

    @media only screen and (min-height: 1081px) {
      min-height: auto;
      height: auto;
      padding-bottom: 40px;
    }
  }
}

.empty-column {
  width: 100%;
  padding-bottom: 110%;

  @media only screen and (min-width: 835px) {
    width: 45%;
    padding-bottom: 0;
  }

  @media only screen and (min-width: 1025px) {
    width: 42.5%;
  }
}
